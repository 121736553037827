import React from 'react'
import { Link } from 'gatsby'

import '../../styles/proyectos.scss'

import ProyectosPropios from '../../images/proyectos_propios.png'
import GestionTierras from '../../images/gestion_tierras.png'
import GerenciaProyectos from '../../images/gerencia_proyectos.png'

export default function Proyectos() {
	return (
		<div className="proyectos" id="proyectos">
			<div className="proyectos__title">
				<div className="proyectos__texture" />
				<h1>PROYECTOS Y SERVICIOS</h1>
			</div>
			<div className="proyectos__logo-container">
				<div className="proyectos__card">
					<Link to="/proyectos/proyectos-propios">
						<img className="proyectos__logo" src={ProyectosPropios} alt="Grupo Ínsula Proyectos Propios" />
					</Link>
					<Link to="/proyectos/proyectos-propios" className="proyectos__button">
						Proyectos <strong>propios</strong>
					</Link>
				</div>
				<div className="proyectos__card">
					<Link to="/proyectos/gestion-de-tierras">
						<img className="proyectos__logo" src={GestionTierras} alt="Grupo Ínsula Gestión de Tierras" />
					</Link>
					<Link to="/proyectos/gestion-de-tierras" className="proyectos__button">
						Gestión de <strong>tierras</strong>
					</Link>
				</div>
				<div className="proyectos__card proyectos__card--3">
					<Link to="/proyectos/gerencia-de-proyectos">
						<img
							className="proyectos__logo"
							src={GerenciaProyectos}
							alt="Grupo Ínsula Gerencia de Proyectos"
						/>
					</Link>
					<Link to="/proyectos/gerencia-de-proyectos" className="proyectos__button">
						Gerencia de <strong>proyectos</strong>
					</Link>
				</div>
			</div>
		</div>
	)
}
